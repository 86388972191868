module.exports = new Promise((resolve, reject) => {
  const w = window;
  const mfeName = "LinkApp_TiktokProfileV2";
  if (w[mfeName]) {
    return resolve(w[mfeName]);
  }

  const qp = new URLSearchParams(w.location.search);
  const version =
    qp.get("LinkApp-TiktokProfileV2-version") || "";
  const file = version ? `remoteEntry.${version}.js` : "remoteEntry.js";
  const url = new URL(file, "https://link-types-assets.qa.linktr.ee/tiktok-profile-v2/").href;

  const script = document.createElement("script");
  script.src = url;
  script.onerror = (evt, src, ln, cl, err) => {
    script.remove();
    reject(err);
  };
  script.onload = () => {
    script.remove();
    if (w[mfeName]) {
      resolve(w[mfeName]);
    } else {
      reject(
        new Error(`MFE did not initialise window["${mfeName}"] from ${url}`),
      );
    }
  };
  document.head.appendChild(script);
});
;